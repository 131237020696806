
import { useStore } from 'vuex'
import { computed, defineComponent, onMounted, reactive, ref } from 'vue'
import moment from 'moment'
import dayjs, { Dayjs } from 'dayjs'
import {appURL} from "@/axios";

export default defineComponent({
  components: {},
  setup () {
    const store = useStore()
    const filter = reactive({
      site_id: '',
      site_product_id: '',
      month: ''
    })

    const getAllSites = () => {
      store.dispatch('Option/getAllSites')
    }

    const getAllSiteProduct = (payload: any) => {
      store.dispatch('Option/getAllSiteProduct', payload)
    }

    const handleChange = (payload: any) => {
      getAllSiteProduct({
        ...payload,
        selected_site_id: payload
      })
    }

    const handleExportToExcel = (e: any) => {
      let month = moment(filter.month).format('MM')
      let year = moment(filter.month).format('YYYY')

      let api_url = process.env.VUE_APP_URL
      window.location.href = appURL + `backend/exports/variance/monthly?type=xlsx&site_id=${filter.site_id}&site_product_id=${filter.site_product_id}&month=${month}&year=${year}`
    }

    const handleExportToPDF = (e: any) => {
      let month = moment(filter.month).format('MM')
      let year = moment(filter.month).format('YYYY')

      let api_url = process.env.VUE_APP_URL
      window.location.href = appURL + `backend/exports/variance/monthly?type=pdf&site_id=${filter.site_id}&site_product_id=${filter.site_product_id}&month=${month}&year=${year}`
    }

    const setDisableExcel = () => {
      return filter.site_id === '' || filter.month === ''  || filter.site_product_id === ''
    }

    const setDisablePdf = () => {
      return filter.site_product_id === "all" || filter.site_id === '' || filter.month === '' || filter.site_product_id === ''
    }

    const disabledDate = (current: Dayjs) => {
      return current > dayjs().endOf('day')
    }

    const filterOption = (input, option) => {
      return option.children[0].children.toLowerCase().indexOf(input.toLowerCase()) >= 0
    }

    const all_sites = computed(() => store.getters['Option/all_sites'])
    const all_site_products = computed(() => store.getters['Option/all_site_products'])
    const isDisabledExcel = computed(() => setDisableExcel())
    const isDisabledPdf = computed(() => setDisablePdf())

    onMounted(() => {
      getAllSites()
    })

    return {
      filter,
      all_sites,
      all_site_products,
      isDisabledExcel,
      isDisabledPdf,
      handleChange,
      handleExportToExcel,
      handleExportToPDF,
      disabledDate,
      filterOption
    }
  }
})
