
import { useStore } from 'vuex'
import { computed, defineComponent, onMounted, reactive, ref } from 'vue'
import dayjs, { Dayjs } from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import {appURL} from "@/axios";

export default defineComponent({
  components: {},
  setup () {
    const store = useStore()
    const filter = reactive({
      site_id: '',
      site_product_id: '',
      from: '',
      period: ''
    })

    const period = ref([
      {
        id: '5',
        name: '5 Days',
      },
      {
        id: '30',
        name: '30 Days',
      },
    ])

    const getAllSites = () => {
      store.dispatch('Option/getAllSites')
    }

    const getAllSiteProduct = (payload: any) => {
      store.dispatch('Option/getAllSiteProduct', payload)
    }

    const handleChange = (payload: any) => {
      getAllSiteProduct({
        ...payload,
        selected_site_id: payload
      })
    }

    const handleExportToExcel = (e: any) => {
      let api_url = process.env.VUE_APP_URL
      window.location.href = appURL + `backend/exports/variance/rolling?period=${filter.period}
      &site_id=${filter.site_id}&site_product_id=${filter.site_product_id}
      &from=${dayjs(filter.from, 'YYYY-MM-DD')}&type=xlsx`
    }

    const handleExportToPDF = (e: any) => {
      let api_url = process.env.VUE_APP_URL
      window.location.href = appURL + `backend/exports/variance/rolling?type=pdf&period=${filter.period}
      &site_id=${filter.site_id}&site_product_id=${filter.site_product_id}
      &from=${dayjs(filter.from, 'YYYY-MM-DD')}`
    }

    const setDisableExcel = () => {
      return filter.site_id === '' || filter.from === ''  || filter.site_product_id === '' || filter.period === ''
    }

    const setDisablePdf = () => {
      return filter.site_product_id === "all" || filter.site_id === '' || filter.from === '' || filter.site_product_id === '' || filter.period === ''
    }
    const disabledDate = (current: Dayjs) => {
      return current > dayjs().endOf('day')
    }

    const filterOption = (input, option) => {
      return option.children[0].children.toLowerCase().indexOf(input.toLowerCase()) >= 0
    }

    const all_sites = computed(() => store.getters['Option/all_sites'])
    const all_site_products = computed(() => store.getters['Option/all_site_products'])
    const isDisabledExcel = computed(() => setDisableExcel())
    const isDisabledPdf = computed(() => setDisablePdf())

    onMounted(() => {
      getAllSites()
    })

    return {
      filter,
      all_sites,
      all_site_products,
      period,
      handleChange,
      handleExportToExcel,
      handleExportToPDF,
      isDisabledExcel,
      isDisabledPdf,
      disabledDate,
      filterOption
    }
  }
})
