
import { useStore } from 'vuex'
import { defineComponent, reactive, onMounted, computed} from 'vue'
import DailyVariance from './daily/DailyVariance.vue'
import RollingVariance from './5and30Days/5and30Days.vue'
import Monthly from './monthly/Monthly.vue'

export default defineComponent({
  components: {
    DailyVariance,
    RollingVariance,
    Monthly
  },

});
